.bg-nav{
    /* From https://css.glass */
/* From https://css.glass */
/* From https://css.glass */
background: rgba(255, 255, 255, 0.28);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.8px);
-webkit-backdrop-filter: blur(12.8px);
border: 1px solid rgba(255, 255, 255, 0.73);
position: fixed;
}

.text{
    text-shadow: 0px 0px 4px black;
}

.button{
    box-shadow: -2px -2px 3px #ffffff, 1px 2px 5px #000000;
}
.navbar-main{
    width:80%;
}
.mark{
    color: #000000;
    box-shadow: 0px 0px 2px black;
}